import logo from '../assets/img/logos/TripAdmit.png';
import logoWhite from '../assets/img/logos/TripAdmitWhite.png';
import rollingBanner1Web from '../assets/img/banners/HDDesktop_WL_Home [Banner2].jpg';
import rollingBanner2Web from '../assets/img/banners/RollingBanner2.jpg';
import rollingBanner3Web from '../assets/img/banners/RollingBanner3.jpg';
import rollingBanner4Web from '../assets/img/banners/RollingBanner4.jpg';
import rollingBanner5Web from '../assets/img/banners/RollingBanner5.jpg';
import rollingBanner1Mob from '../assets/img/banners/Phone_WL_Home [Banner2].jpg';
import rollingBanner1Tab from '../assets/img/banners/iPad_WL_Home [Banner2].jpg';
import rollingBanner2Mob from '../assets/img/banners/RollingBanner2MobileWeb.jpg';
import rollingBanner3Mob from '../assets/img/banners/RollingBanner3MobileWeb.jpg';
import rollingBanner4Mob from '../assets/img/banners/RollingBanner4MobileWeb.jpg';
import rollingBanner5Mob from '../assets/img/banners/RollingBanner5MobileWeb.jpg';
import localization from '../localization';
var banner = require('../assets/img/banners/Campsited2.jpg');
var favicon = require('../assets/img/Eticket.svg');

export default {
  name: 'TripAdmit',
  clientName: 'TripAdmit',
  config: {
    showMapsByDefault: false,
  },
  meta: {
    title: 'TripAdmit - Tours and Activities',
    description: 'Connecting Experiences',
    home: '/home',
  },
  colors: {
    primary: '#6997ff',
    secondary: '#6997ff',
    inactive: '#BCBCBC',
  },
  fonts: {
    colors: {
      primary: '#6997ff',
      secondary: '#6997ff',
      inactive: '#BCBCBC',
    },
    primary: 'MontserratRegular',
    regular: 'MontserratRegular',
    light: 'MontserratRegular',
    bold: 'MontserratBold',
  },
  styles: {},
  bannerImages: {
    images: [
      {
        web: rollingBanner1Web,
        mob: rollingBanner1Mob,
        tab: rollingBanner1Tab,
        text: localization.rollingBanner.static,
        link: '',
      },
    ],
  },
  assets: {
    logo,
    favicon,
    banner,
    logoWhite,
  },
  currency: {
    default: 'INR',
    defaultWithSymbol: 'INR ₹',
    available: {
      INR: 'INR ₹',
      USD: 'USD $',
      EUR: 'EUR €',
    },
  },
  language: {
    default: 'en',
    available: ['en', 'sr', 'fr'],
  },
};
