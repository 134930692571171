import { getExperimentOption } from '@analytics';
import { StyleBreakpoints } from '@constants';
import { REINFORCEMENT_MESSAGES_HOMEPAGE_LOCATION_V1 } from '@experimentConstants';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Bold } from '../../components/Common';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Places from '../../components/Places';
import PoweredBy from '../../components/PoweredBy';
import StaticBanner from '../../components/StaticBanner';
import TourCarouselNew from '../../components/TourCarouselNew';
import TourSearch from '../../components/TourSearch';
import TrustBuilders from '../../components/TrustBuilders';
import localization from '../../localization';
import { FETCH_LOCATIONS } from '../../store/locations/actionTypes';
import { FETCH_FEATURED_TOURS } from '../../store/tours/actionTypes';
import { FontSizes } from '../../utils/constants';
import localTheme from 'theme';

const Component = styled.div`
  font-family: ${(props) => props.theme.fonts.regular};
  background-color: #f6f7fa;
`;
const Wrapper = styled.div`
  width: 90vw;
  margin: auto;
  @media (max-width: ${StyleBreakpoints.sm}px) {
    width: 90%;
  }
`;
const Block = styled.div`
  font-size: ${FontSizes.h2};
  margin-top: 42px;
  color: black;
  letter-spacing: 0.07pt;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 24px;
  line-height: 38px;
`;

const useStyles = makeStyles((theme) => ({
  sticky: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    paddingLeft: '20%',
    paddingRight: '20%',
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `#04112D`,
    padding: '12px',
    animation: `$animateInBar 200ms ${theme.transitions.easing.easeInOut}`,
  },
  stickyBarLogoContainer: {
    position: 'absolute',
    left: '2%',
    [theme.breakpoints.down('950')]: {
      display: 'none',
    },
  },
  stickyBarLogo: {
    display: 'block',
    height: '62px',
    backgroundSize: '197px 45px',
  },
  searchBar: {
    width: '60vw',
    [theme.breakpoints.down('950')]: {
      width: '90vw',
    },
  },
  '@keyframes animateInBar': {
    '0%': {
      opacity: 0,
      transform: 'translateY(-200%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
}));

function Home({ attractions, cities, tours, loadAttractions, loadCities, loadFeaturedTours }) {
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const [renderTrustBuilders, setRenderTrustBuilders] = useState({});
  const searchRef = useRef(null);
  const myRef = useRef(null);
  const classes = useStyles();

  let logoPath = localTheme.assets.logo;
  let linkToParentSite = localTheme.config.parentSite;

  useEffect(() => {
    loadAttractions(0);
    loadCities(0);
    loadFeaturedTours(0);

    const search = searchRef.current.getBoundingClientRect();

    const handleScrollEvent = () => {
      handleScroll(search.top, search.height);
    };

    window.addEventListener('scroll', handleScrollEvent);

    let trustBuilderLocation = getExperimentOption(
      REINFORCEMENT_MESSAGES_HOMEPAGE_LOCATION_V1.name
    );
    setRenderTrustBuilders(trustBuilderLocation);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > elTopOffset + elHeight) {
      setSticky({ isSticky: true, offset: 0 });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  return (
    <Component style={{ marginTop: sticky.offset }}>
      <Header
        isHomePage
        extraTrackingProperties={{
          'display area': 'header bar',
          page: 'home',
        }}
      />
      <StaticBanner>
        <div ref={searchRef} className={`${sticky.isSticky ? classes.sticky : classes.searchBar}`}>
          {sticky.isSticky ? (
            <div className={classes.stickyBarLogoContainer}>
              <img
                className={classes.stickyBarLogo}
                src={logoPath}
                style={{ filter: 'contrast(0) brightness(100)' }}
              ></img>
            </div>
          ) : null}
          <TourSearch
            extraTrackingProperties={{ page: 'home' }}
            myRef={myRef}
            style={{ position: 'sticky' }}
          />
        </div>
      </StaticBanner>
      <Wrapper>
        {renderTrustBuilders ===
          REINFORCEMENT_MESSAGES_HOMEPAGE_LOCATION_V1.values.Below_Banner && <TrustBuilders />}

        <Block>
          <Bold>{localization.home.ourTopDestination}</Bold>
        </Block>
        <Places destinations={cities.list} />
        {renderTrustBuilders ===
          REINFORCEMENT_MESSAGES_HOMEPAGE_LOCATION_V1.values.Below_Destinations && (
          <TrustBuilders />
        )}

        <Block>
          <Bold>{localization.home.ourTopPicks}</Bold>
        </Block>
        <TourCarouselNew tours={tours.list} />

        <div ref={myRef}></div>
        <Block>
          <Bold>{localization.home.mostPopularAttractions}</Bold>
        </Block>
        <Places isAttractions />
        <br />
        <br />
      </Wrapper>
      <PoweredBy />
      <Footer />
    </Component>
  );
}

export default connect(
  (store) => ({
    attractions: store.location.attractions,
    cities: store.location.cities,
    tours: store.tours.featured,
  }),
  (dispatch) => ({
    loadAttractions: (page) =>
      dispatch({
        type: FETCH_LOCATIONS,
        payload: {
          page,
          type: 'POI',
          global: 'attractions',
          language: localization.getLanguage(),
        },
      }),
    loadCities: (page) =>
      dispatch({
        type: FETCH_LOCATIONS,
        payload: {
          page,
          type: 'CITY',
          global: 'cities',
          language: localization.getLanguage(),
        },
      }),
    loadFeaturedTours: (page) =>
      dispatch({
        type: FETCH_FEATURED_TOURS,
        payload: {
          page,
          perPage: 8,
          language: localization.getLanguage(),
        },
      }),
  })
)(Home);
